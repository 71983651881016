<template>
  <DetailDrawer
    :title="broadcastView.title"
    :message="broadcastView.message"
    :album-name="broadcastView.albumName"
    :attachments="broadcastView.attachments"
  />
  <div class="tab-heading-and-action">
    <div>
      <h3>Broadcast List  </h3>
    </div> 
    
    
    <div class="filter">
      <div class="input-group">
        <fa icon="search" />
        <input
          v-model="name"
          type="text"
          placeholder="Search"
        >
        <fa
          v-show="name!=''"
          icon="xmark"
          class="pointer"
          @click="name=''"
        />
      </div>
      <router-link
        :to="'/create-broadcast/'" 
        class="add-speakers"
      >
        <fa icon="plus" /> Create Broadcast
      </router-link>
    </div>
  </div>
 
  <v-data-table-server
    v-model:items-per-page="itemsPerPage"
    :height="tableHeight"
    fixed-header
    :search="search"
    :headers="headers"
    :items-length="totalItems"
    :items="serverItems"
    :loading="loading"
    item-value="name"
    @update:options="loadItems"
  >
    <template #[`item.title`]="{ item }">
      <v-tooltip
        v-if="item.title!='' && item.title.length > 8 "
        :text="item.title"
      >
        <template #activator="{ props }">
          <div
           
            v-bind="props"
            class="pointer"
          >
            {{ truncateString((item.title),28) }}
          </div>
        </template>
      </v-tooltip>
      <div v-else>
        {{ item.title }}
      </div>
    </template> 

    <template #[`item.message`]="{ item }">
      <div>
        {{ truncateString(item.message,40) }}
      </div>
    </template> 



    <!--     
    <template #[`item.attachments`]="{ item }">
      <v-tooltip
        v-if="item.attachments!='' && item.attachments.length > 8 "
        :text="item.attachments"
      >
        <template #activator="{ props }">
          <div
           
            v-bind="props"
            class="pointer"
          >
            {{ truncatedString(item.attachments,50) }}
          </div>
        </template>
      </v-tooltip>
      <div v-else>
        {{ item.attachments }}
      </div>
    </template>  -->





    

    <template #[`item.action`]="{ item }">
      <v-tooltip text="View">
        <template #activator="{ props }">
          <fa 
            v-bind="props"
            icon="eye"
            class="pointer"
            @click="viewMember(item.view)"
          />
        </template>
      </v-tooltip>


      <v-tooltip text="Edit">
        <template #activator="{ props }">
          <router-link :to="'/edit-broadcast/'+item.action">
            <fa
              v-bind="props"
              icon="pen-to-square"
              class="pointer"
            />
          </router-link>
          <!-- <fa 
            v-bind="props"
            icon="pen-to-square"
            class="pointer"
            @click="editMember(item.view)"
          />-->
        </template> 
      </v-tooltip>
      <!-- <fa
        icon="paper-plane"
        class="pointer"
      /> -->
    </template>
  </v-data-table-server>


  <div
    v-if="viewDialog"
    class="view-dialog-overlay"
    @click.self="viewDialog = false"
  >
    <div class="view-member-modal drawer-active">
      <div class="view-modal-action">
        <fa
          icon="arrow-right-long"
          @click="viewDialog = false"
        />
        <h3>{{ truncatedString(broadcastView.title) }}</h3>
      </div>

      <div class="view-member-details">
        <div class="member-details-content">
          <h5>Title</h5>
          <p>{{ broadcastView.title }}</p>
          <h5>Message</h5>
          <MarkdownRenderer :source="broadcastView.message" />
          <br>
          <h5>Gallery</h5>
          <p>{{ broadcastView.albumName }}</p>
          <h5>Attachments</h5>
          <div
            v-if="broadcastView.attachments.length > 0"
            class="attachments-container"
          >
            <div
            
              v-for="item in broadcastView.attachments"
              :key="item"
            >
              <v-tooltip
                v-if="item.fileName!='' && item.fileName.length > 8 "
                :text="item.fileName.split('/').pop().substring(36)"
              >
                <template #activator="{ props }">
                  <div
                    target="_blank"
                    href="#"
                    v-bind="props"
                  >
                    <img
                      v-if="getFileExtension(item.fileName)=='jpg' || getFileExtension(item.fileName)=='png'"
                      :src="'data:image/jpeg;base64,'+item.source"
                      class="attachments-thumbnail"
                    >
                  </div>
                </template>
              </v-tooltip>
              <v-tooltip
                v-if="item.fileName!='' && item.fileName.length > 8 "
                :text="item.fileName?.split('/').pop().substring(36)"
              >
                <template #activator="{ props }">
                  <div
                    v-if="getFileExtension(item.fileName)=='pdf' || getFileExtension(item.fileName)=='docx'"
                    target="_blank"
                    href="#"
                    v-bind="props"
                    class="attachments-thumbnail"
                  />
                </template>
              </v-tooltip>
            </div>
          </div>
          <div v-else>
            <p>N/A</p>
          </div>
        </div>
      </div>
    </div>
  </div>


  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
  >
    {{ text }}

    <template #actions>
      <v-btn
        color="blue"
        variant="text"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>





<script setup>
import MarkdownRenderer from '@/components/ui/MarkdownRenderer.vue'
import DetailDrawer  from '@/components/broadcast/DetailDrawer.vue';
import {ref,reactive,watch,provide,onBeforeMount} from 'vue'
import { useStore } from 'vuex';
import { useRoute,useRouter } from 'vue-router';
import axios from 'axios';
import { truncateString,formattedDate } from '@/helper/index.js';
import {getBroadcastList,getImage} from '@/service/helper-service.js';

const store = useStore(); 
const router = useRouter();


const itemsPerPage = ref(5);
const offset = ref(0);
const headers = ref([
	{ title: 'Title', value: 'title' },
	{ title: 'Message', value: 'message' },
	// { title: 'Attachments', value: 'attachments' },
	{ title: 'Date', value: 'date' },
	{ title: 'Action', value: 'action' }
]);

const serverItems = ref([]);
const loading= ref(true);
const totalItems= ref(0);
const speakerId=ref('');
const mode =  ref('add');
const broadcastView = reactive({
	title:'',
	message:'',
	albumName:'',
	attachments:[]
})

const name= ref('');
const	search= ref('');
const	snackbar=ref(false);
const viewDialog=ref(false);
const deleteDialog=ref(false);
const	text= ref('');
const	timeout= ref(2000);
const tableHeight = ref(0);
let openBroadcastDetail = reactive({'status':false,'id':''});
provide('openBroadcastDetail',openBroadcastDetail)


onBeforeMount(() => {
	  if (window.innerHeight <= 657) {
		itemsPerPage.value = 8;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 80% */
	if (window.innerHeight <= 730) {
		itemsPerPage.value = 12;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 80% */
	if (window.innerHeight <= 821) {
		itemsPerPage.value = 13;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 75% */
	if (window.innerHeight <= 876) {
		itemsPerPage.value = 17;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 67% */
	if (window.innerHeight <= 986) {
		itemsPerPage.value = 21;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 50% */
	if (window.innerHeight <= 1314) {
		itemsPerPage.value = 31;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 33.3% */
	if (window.innerHeight <= 1971) {
		itemsPerPage.value = 41;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
	/* 25% */
	if (window.innerHeight <= 2628) {
		itemsPerPage.value = 48;
		tableHeight.value = window.innerHeight - 220;
		return;
	}
})

watch(name, () => {
	clearTimeout(timeout.value);
	timeout.value= setTimeout(()=> {
		search.value = String(Date.now())
	},1000)
});





const loadItems  = async({ page, itemsPerPage, sortBy })=> {
	offset.value = (page - 1) * itemsPerPage
	console.log("offset",offset.value);
	loading.value = true
	// ?limit=${itemsPerPage}&offset=${offset.value}
	try {
		const response = await getBroadcastList(`/admin/broadcast/list?limit=${itemsPerPage}&offset=${offset.value}`,{
			value:name.value});
		serverItems.value = []
		console.log('response.data.title',response.data);
		// serverItems = response.data.data;
		totalItems.value = response?.data?.data?.totalCount;
		loading.value = false
        
		let broadcastData = response?.data?.data?.data;
		console.log('broadcastData',broadcastData);
		broadcastData.forEach((item,index) => {
			// let fileNames = item?.files.map(str => {return str?.split("/").pop().substring(36)});
			console.log('item',item)
			//console.log('fileNames', fileNames)
			serverItems.value.push({title: item?.title || '',
				message:  item?.content,
				// attachments: fileNames?fileNames.join(', '):'' ,
				date:formattedDate(item?.createdAt),
				action:item._id,
				view:item})
		});

	} catch (error) {
		if (error.response) {
			if(error.response.data.message){
				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});

			}else{
  
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			console.log("error.message",error.message)
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}
	}
      
	
        
}



	

const viewMember = (data)=>{
	openBroadcastDetail.status = true;
	broadcastView.title = data?.title || '';
	broadcastView.message = data?.content || 'N/A';
	broadcastView.albumName = data?.galleryDetails?.albumName || 'N/A';
	broadcastView.attachments = [];
	if(data?.files){
		if(data?.files.length > 0){
			data?.files.forEach((item)=>{
				console.log('attac',item)
				loadImage(item.split('/profile/')[1])
			})
		}
	}

	
}




const truncatedString = (string) =>{
	let result = string.substring(0, 10);
	// Add dots if the original string length is longer than 8 characters
	if (string.length > 8) {
		result += "..";
	}
	return result;
}




const loadImage = async (fileName)=>{

	try {
		const response = await getImage(`/files?name=${fileName}`);
		if(response.status === 200){
			broadcastView.attachments.push({source:response?.data?.data,options:{type:'local'},fileName:fileName});
			console.log("FILE",broadcastView.attachments);
		}
	} catch (error) {
		if (error.response) {
			if(error.response.data.message){
				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});

			}else{

				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			console.log("error.message",error.message)
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}
	}


}

const editMember = (item)=>{
	const id = item._id; // Example ID you want to pass
  router.push({ name: 'editBroadcast', params: { id } });
	store.commit('broadcast/setBroadcast',item);
}



</script>

<style scoped>
.tab-heading-and-action{display: flex;margin-top: 12px;margin-bottom: 13px;align-items: baseline;}
.tab-heading-and-action h3{font-size: 22px;}
.tab-heading-and-action .fa-filter{color: gray;margin-left: 12px;}
.filter{width: max-content;margin-left: auto;display: flex;align-items: center;}
.input-group{border-radius: 8px;
    border: 1px solid #E7E7E7;
    background: #F5F5F5;
    padding: 4px 5px;}
 .input-group .fa-magnifying-glass{color:#7E7E7E;margin-right: 8px;margin-left: 15px;}  
 .input-group input{outline: unset;height: 32px;}

 .add-speakers{  border-radius: 5px;
    background: #007CBA;
    color: white;
    padding: 8px 14px;
    margin-left: 16px;
    cursor: pointer;
    text-decoration:none;}
.add-speakers .fa-plus{margin-right: 8px;}

.v-table :deep() thead{ background-color: #65A4C4;color:#fff;}
.v-table :deep() thead tr > th{width: 120px;}
.v-table :deep() thead tr > th:nth-child(2){width:300px;}
.v-table :deep() tbody tr > td{text-align: left;}
.v-table :deep() tbody tr:nth-child(even){background-color: #ECF2F6;border-top:unset!important;}
.v-table :deep() tbody tr:nth-child(odd) > td{border-bottom:1px solid #fff;}
.v-table :deep() tbody tr:nth-child(even) > td{border-bottom:1px solid #ECF2F6;}
.v-table :deep() tbody tr > td .fa-eye{color:#007CBA;margin-right: 5px;}
.v-table :deep() tbody tr > td .fa-pen-to-square{margin-right: 5px;color:#007CBA}
.v-table :deep() tbody tr > td .fa-trash{color:#EC1B1B;margin-right: 5px;}
.v-table :deep() tbody tr > td .fa-paper-plane{color: #007CBA;}

.pointer{cursor: pointer;}

.delete-dialog {position: relative!important; background-color: #FFF9F9;border-radius: 12px!important;}
.delete-dialog .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}
.v-card-text h3{display: flex;align-items: center;}
.v-card-text .fa-trash-can{color: #EC1B1B;
    background-color: #FEE4E2;
    padding: 9px;
    border-radius: 50%;
    font-size: 15px;
    margin-right: 12px;}
.delete-action{padding: 9px 21px;display: flex;justify-content: flex-end;}
.delete-action button:nth-child(1) {background-color: white;color: black;margin-right: 12px;border-color: #989898;}
.delete-action button:nth-child(2) {background-color: #D92D20;color: white;border-color:#D92D20;}
.delete-action button{padding: 6px 13px;
    border-radius: 9px;
    border: 1px solid;}
.view-dialog-overlay{position: fixed;left: 0;right: 0;bottom: 0;top: 0; background-color: #08080857;width:100%;height: 100vh;z-index:1040;}
 .view-dialog-overlay .view-member-modal{background-color: white;position:absolute;right: 0;z-index: 30000;width: 320px;height: 100vh;border-radius: 16px 0 0 16px;padding: 16px;}
.view-modal-action{display: flex;align-items: center;margin-top: 14px;margin-bottom: 14px;}
.view-modal-action .fa-arrow-right-long{cursor: pointer;}
.view-modal-action h3{margin-left: 18px;}
.view-member-details{position: relative;height:calc(100% - 100px) ;overflow-y:scroll;}
  .view-member-details::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}
.view-member-details::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}
.view-member-details::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #C9C9C9;
}
.member-details-content{position:sticky;text-align: left;}
.member-details-content h5{color: #858585;font-weight: 500;
    font-size: 16px;}
.member-details-content p{color: #242426;font-weight: 600;
    margin-bottom: 23px;}
 .member-details-content button{
    border: 1px solid #EC1B1B;
    color: #EC1B1B;
    padding: 8px 10px;
    display: block;
    margin: 0 auto 12px;
    border-radius: 5px;
    font-weight: 600;
    min-width: 200px;}   
    .approve{background-color: #007CBA;border: 1px solid #007CBA!important;color:white!important;}
    .drawer-active{
      animation: fade-in 500ms forwards;
	}

 
	
	@keyframes fade-in{
	0%{
	opacity: 0;
	right: -320px;
	}
	100%{
	opacity: 1;
	right: 0px;
	}
	}
  

  @media only screen and (max-width: 600px){
		.tab-heading-and-action{flex-direction: column;}
		.filter{margin-left: unset;flex-direction: column;align-items: unset;}
    .add-speakers{margin-top: 12px;margin-left: unset;}
	}
</style>






