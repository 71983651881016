import apiClient from '@/service/api-service.js';


// Member
export const getMemberCount = (path) => {
	return apiClient.get(path);
};

export const getMemberList = (path,params) => {
	return apiClient.get(path, {
		params:params
	});
};

export const getMember = (path) => {
	return apiClient.get(path);
};

export const activateUser = (path,body) => {
	return apiClient.put(path,body);
};

export const deactivateUser = (path,body) => {
	return apiClient.put(path,body);
};

export const generateCredentials = (path,body) => {
	return apiClient.post(path,body);
};



// Add Member
export const createMember = (method,path,body) => {
	return apiClient[method](path,body);
};

export const uploadImage = (path,body) => {
	return apiClient.post(path,body);
};

export const deleteProfile = (path) => {
	return apiClient.delete(path);
};

export const getImage = (path) => {
	return apiClient.get(path);
};

// Broadcast
export const createBroadcast = (method,path,body) => {
	return apiClient[method](path,body);
};

export const getAllAlbum = (path) => {
	return apiClient.get(path);
};

export const getAttachments = (path) => {
	return apiClient.get(path);
};

export const getBroadcastList = (path,params) => {
	return apiClient.get(path, {
		params:params
	});
	// return apiClient.get(path);
};

// Album List
export const createAlbum = (method,path,body) => {
	return apiClient[method](path,body);
};


export const getAlbumList = (path) => {
	return apiClient.get(path);
};

//event
export const getEventData = (path) => {
	return apiClient.get(path);
};

export const getTopicDetail = (path) => {
	return apiClient.get(path);
};

export const getTrackData = (path) => {
	return apiClient.get(path);
};

export const removeTrackDetails = (path) => {
	return apiClient.delete(path);
};




//scheduler
export const getAllSpeaker = (path) => {
	return apiClient.get(path);
};

export const getHallList = (path) => {
	return apiClient.get(path);
};

export const getQueueData = (path) => {
	return apiClient.get(path);
};

export const addEventTopic = (method,path,body) => {
	return apiClient[method](path,body);
};

export const updateSchedule = (method,path,body) => {
	return apiClient[method](path,body);
};

export const getSchedule = (path) => {
	return apiClient.get(path);
};

export const getProfile = (path) => {
	return apiClient.get(path);
};

export const storeTrack = (method,path,body) => {
	return apiClient[method](path,body);
};

export const removeTopic = (path) => {
	return apiClient.delete(path);
};
// OfficeBearer
export const createOfficeBearer = (path,body) => {
	return apiClient.put(path,body);
};

export const getOfficeBearerList = (path,params) => {
	return apiClient.get(path, {
		params:params
	});
};

export const getMemberRole = (path) => {
	return apiClient.get(path);
};

export const getAllMemberList = (path) => {
	return apiClient.get(path);
};




// Add more API calls as needed
