<template>
  <AdminPanel>
    <div class="form_tab_container">
      <div
        class="card "
        :class="{active:selectedFormTab ==='AddMember'}"
        @click="selectFormTab('AddMember')"
      >
        <div class="card-name ">
          Add Member
        </div>
      </div>

      <div
        class="card"
        :class="{active:selectedFormTab ==='AddMultipleMember'}"
        @click="selectFormTab('AddMultipleMember')"
      >
        <div class="card-name">
          Add Multiple Member
        </div>
      </div>
    </div>
    <v-container>
      <div>
        <AddMember v-if="selectedFormTab ==='AddMember'" />
        <AddMultipleMember v-if="selectedFormTab ==='AddMultipleMember'" />
      </div>
    </v-container>
  </AdminPanel>
</template>


<script setup>
// @ is an alias to /src
import { useRoute,useRouter } from 'vue-router';
import {ref} from 'vue'
import AdminPanel from '@/components/ui/AdminPanel.vue'
import AddMember from '@/components/add_members/AddMember.vue'
import AddMultipleMember from '@/components/add_members/AddMultipleMember.vue'



const route = useRoute();
const memberId = ref(route.params.id);
const selectedFormTab = ref('AddMember');
const selectFormTab = (tabName)=>{
	selectedFormTab.value = tabName;
}

</script>

<style scoped>
.form_tab_container{display: flex;
    border-bottom: 1px solid #B2B2B2;
    height: 70px;
    justify-content: center;}
  .card{min-width: 250px;
     height: 70px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    margin-right: 20px;
    color: #9D9D9D;}
  .card:last-child{ margin-right: unset;}
  .active{color:black;position: relative;}  
 .active::after{position: absolute;content: '';left: 0;bottom: 0; width:100%;height: 5px; background-color:#007CBA;}
 .card-name{
  text-align: center;
  font-weight: 700;
    font-size: 20px;
   }   

</style>


