<template>
  <div class="v-container">
    <h3>{{ formHeading }}</h3>
  </div>
  
  <Form
    ref="form"
    :validation-schema="schema"
    @submit="onSubmit"
  >
    <div class="form-container">
      <div class="form-card">
        <v-skeleton-loader
          v-show="fetching"
          type="ossein"
          height="250"
        />
        <div
          v-show="!fetching"
          class="form-sets"
        >
          <h3>Message</h3>
         
          <div class="form-col-full">
            <label for="title">Title</label>
            <Field
              v-model="title"
              name="title"
              type="text"
              placeholder="Title"
            />
            <ErrorMessage name="title" /> 
          </div>
           
          <div class="form-col-full">
            <label for="message">Message<span>*</span> </label>
            <Field
              id="message"
              v-slot="{field}"
              ref="messageArea"
              v-model="message"
              name="message"
              type="text"
              as="textarea"
              style="resize: none; overflow-y: hidden;"
			  
              @input="adjustTextareaHeight"
            />
            <ErrorMessage name="message" />
          </div> 
      
          <div class="form-row">
            <div class="form-col-full">
              <div class="form-actions">
                <button
                  v-show="!broadcastId"
                  type="button"
                  @click="resetForm"
                >
                  Reset
                </button>
                <button
                  v-show="broadcastId"
                  type="button"
                  @click="cancelUpdate"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  :disabled="saveBroadcast?true:false"
                >
                  Send  
                </button>
              </div>
            </div>
          </div>
          <!-- <div
            class="form-col-full"
            style="margin-bottom: 0;"
          >
            <label for="gallery">Gallery </label>
  
            <Field
              v-model="gallery"
              name="gallery"
              as="select"
            >
              <option
                value=""
              >
                Gallery
              </option>
              <option
                v-for="item in albumArray"
                :key="item._id"
                :value="item._id"
              >
                {{ item.albumName }}
              </option>
            </Field>
        
            <ErrorMessage name="gallery" />
          </div> -->
        </div>
      </div>
    
      
    
      <!-- <div
        v-show="gallery==''"
        class="form-card"
      >
        <v-skeleton-loader
          v-if="broadcastId && isConverting"
        
          height="240"
          type="image"
        />
        <div
          v-else
          class="form-sets"
        >
          <h3>Files Attachments </h3>
          <div style="position: relative;">
            <file-pond
              ref="pond"
              name="file"
              class-name="my-pond"
              label-idle="<p class='upload-icon'>Drag or upload your files</p>"
              allow-multiple="true"
              accepted-file-types="image/jpeg, image/png, application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              max-file-size="5MB"
              :files="myFiles"
              :server="serverOptions"
              credits="false"
              image-preview-min-height="44"
              image-preview-max-height="100"
              allow-file-size-validation="true"
              label-max-file-size-exceeded="File is too large"
              @init="handleFilePondInit"
            />
            <span class="file-limitation">Files must be under 5 MB and can be in image (JPG, PNG), PDF, or DOC format</span>
          </div>
        </div>
      </div> -->
    </div>

  
    <br>  
    <!-- <div class="form-row">
      <div class="form-col-full">
        <div class="form-actions">
          <button
            v-show="!broadcastId"
            type="button"
            @click="resetForm"
          >
            Reset
          </button>
          <button
            v-show="broadcastId"
            type="button"
            @click="cancelUpdate"
          >
            Cancel
          </button>
          <button
            type="submit"
            :disabled="saveBroadcast?true:false"
          >
            Send  
          </button>
        </div>
      </div>
    </div> -->
  </Form>
  
     
  <!-- snackbar -->
  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
    color="success"
  >
    {{ text }}
  
    <template #actions>
      <v-btn
        color="white"
        variant="text"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
  <!-- snackbar -->
</template>
  
  
  
  
  
<script setup>

import { useStore } from 'vuex';
import { useRoute,useRouter } from 'vue-router';
import {ref,watch,onMounted,onBeforeMount,onUpdated} from 'vue'
import { capitalizeString } from '@/helper/index.js';


  
import { Form, Field, ErrorMessage} from 'vee-validate';
import * as yup from 'yup';
import axios from 'axios';
import {createBroadcast,getAllAlbum,getAttachments} from '@/service/helper-service.js';


import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

const FilePond = vueFilePond(FilePondPluginFileValidateType,FilePondPluginFileValidateSize, FilePondPluginImagePreview);

  

const route = useRoute();
const router = useRouter();
const store = useStore(); 
  
const form  = ref();
  
const imageArray = ref([]);
const localSource = ref([]); 
const title = ref('');
const message = ref('');
const albumArray = ref('');

const pond = ref(null);

const myFiles = ref([]);
const messageArea = ref(null);
const timeout= ref(2000);
const snackbar= ref(false);
const text= ref('');
const broadcastId = ref(route.params.id);
const formHeading = ref('');
const gallery = ref('');
const isConverting = ref(false);
const fetching = ref(false);
const clrTimeout = ref(null);
const saveBroadcast = ref(false);

  
const schema = yup.object({
	title: yup.string()
		.test('title-validation', 'Title should be under 150 characters', function(value) {
			if (value && value.length > 150) {

				return false; // Validation failed
			}
			return true;
  
		}),
	message:yup.string().required('Please enter Message')
  
});
  
watch(gallery, (newValue) => {
	console.log('gallery',newValue)
	if(newValue != ''){
		imageArray.value = [];
	}

})


watch(isConverting, (oldValue,newValue) => {
	localSource.value
	if(newValue === true){
		// myFiles.value = localSource;
		myFiles.value = localSource.value;
		console.log("GGG",myFiles.value)
	}

})



      
const adjustTextareaHeight = (e) => {
	e.target.style.height = 'auto';
	  e.target.style.height = e.target.scrollHeight + 'px';
};


const onSubmit = async(data) => {
	console.log('data',data);
	saveBroadcast.value = true;
 
	
	let createPayload = {
		"content":data.message,
		// "files":  imageArray.value,
		"createdBy": store.state.authentication.id
	}
	if(data.title){
		createPayload.title =  capitalizeString(data.title);
	}	

	let updatePayload = {
		"_id":broadcastId.value,
		"data":{
			"content":data.message,
			// "files":  imageArray.value,
			"createdBy": store.state.authentication.id
		}
		
	}
	if(data.title){
		updatePayload.data.title =  capitalizeString(data.title);
	}	
		
	// if(data.gallery){
	// 	payload.galleryId = data.gallery;
	// }else{
	// 	payload.galleryId = null;
	// }
	
	
	const method = broadcastId.value ? 'put' : 'post';
	const payload = broadcastId.value ? updatePayload : createPayload;
	const url = broadcastId.value ? `${process.env.VUE_APP_SERVICE_URL}/admin/broadcast` : `${process.env.VUE_APP_SERVICE_URL}/admin/broadcast`;

	try {
		const response =  await createBroadcast(method,url,payload);
		if(response.status === 200){
		
			saveBroadcast.value = false;
			if(broadcastId.value){
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:'Updated successfully',
					color:'success',
					timeout:2000});
				myFiles.value =[];
				router.push({name:'broadcast'});
			}else{
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:'Added successfully',
					color:'success',
					timeout:2000});
	  
				resetForm()
			}
		  
		}
	} catch (error) {
		if (error.response) {
			saveBroadcast.value = false;
			if(error.response.data.message){
				console.log("error.response.data.message",error.response.data.message);
				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});

			}else{
					
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
				
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}
	}

	
      
};
  
  
  
const resetForm = ()=>{
	form.value.resetForm()  
	myFiles.value =[];
	imageArray.value = [];
	document.querySelector('#message').style.height='auto';
}
  
  
const editBroadcast = (id)=>{
	fetching.value = true;
	axios
		.get(`${process.env.VUE_APP_SERVICE_URL}/admin/broadcast?id=${id}`,{
			headers: {
				'Authorization': `${store.state.authentication.token}`,
			}})
		.then((response) => {
			console.log("edit response",response);
			console.log('broadcastId',)
			if(response.status === 200){
				// messageArea.value.style.height = 'auto';
				// messageArea.value.style.height = `${messageArea.value.scrollHeight}px`;
				
				let getBroadCastDetails = response?.data?.data;
				title.value = getBroadCastDetails?.title || '';
				message.value = getBroadCastDetails?.content|| '';
				console.log('messageArea.value.scrollHeight',messageArea.value.scrollHeight)
				// 	setTimeout(()=>{
	        //  messageArea.value.style.height = 'auto';
				// 	messageArea.value.style.height = `${messageArea.value.scrollHeight}px`;
				// 	},500)

		
				// gallery.value = getBroadCastDetails?.galleryId || '';
        

				// imageArray.value = getBroadCastDetails?.files || '';

				// for(let i=0;i<getBroadCastDetails.files.length;i++){
				// 	loadAttachments(getBroadCastDetails.files[i])
				// }
				

			}
		}).finally(()=>{
			fetching.value = false;
			let messageTextarea = document.querySelector('#message');
			console.log("messageTextarea",messageTextarea)
			console.log("messageTextarea.scrollHeight",messageTextarea.scrollHeight)
			messageTextarea.style.height = 'auto';
			// messageArea.value.style.height = 'auto';

			setTimeout(()=>{
				//messageArea.value.style.height = `${messageArea.value.scrollHeight}px`;
				console.log("document.querySelector('#message').scrollHeight'",document.querySelector('#message').scrollHeight);
				messageTextarea.style.height = document.querySelector('#message').scrollHeight + 'px';
			},10)
		})
}
  

const loadAlbumList = async() =>{
	
	try {
		const response = await getAllAlbum(`/all/gallery`);
		if(response.status === 200){
			console.log("response",response);
	        albumArray.value = response?.data?.data;
		}
	} catch (error) {
		if (error.response) {
			if(error.response.data.message){
				console.log("error.response.data.message",error.response.data.message);
				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});

			}else{
					
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
				
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}

	}	
	
}


  
onMounted(()=>{
	console.log("ONMOUNTED")
	// let messageTextarea = document.querySelector('#message');
	// messageTextarea.style.height = 'auto';
	//messageArea.value.style.height = 'auto';

	setTimeout(()=>{
		// messageArea.value.style.height = `${messageArea.value.scrollHeight}px`;
		//messageTextarea.style.height = document.querySelector('#message').scrollHeight + 'px';
	},500)
		

	

	if(broadcastId.value){
		formHeading.value="Edit BroadCast";
		editBroadcast(broadcastId.value);
		console.log('broadcast details',store.state.broadcast.broadcastDetail);
		// if(store.state.broadcast.broadcastDetail._id == broadcastId.value){
		// 	title.value = store.state.broadcast.broadcastDetail.title;
		// 	message.value = store.state.broadcast.broadcastDetail.content;
			
		// 	saveBroadcast.value = false;
		// 	formHeading.value="Edit BroadCast";
		// }
	}else{
		formHeading.value="Send BroadCast";
	}
	// loadAlbumList();
 
})



const loadAttachments = async(fileName)=>{
	isConverting.value = true;

	try {
		const response = await getAttachments(`/files?name=${fileName.split('/profile/')[1]}`);
		if(response.status === 200){
			clearTimeout(clrTimeout.value);
			const base64String = response?.data?.data;
			clrTimeout.value= setTimeout(()=> {
				isConverting.value = false;
			},2000)
			localSource.value.push({source:base64toFile(base64String, fileName.split("/").pop().substring(36), getFileExtension(fileName.split('/profile/')[1])),options:{type:'local',fullUrl:fileName}});
			console.log('localSource',localSource.value);
		}
	} catch (error) {
		if (error.response) {
			if(error.response.data.message){
				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});

			}else{

				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
				
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}

	}

}

onUpdated(()=>{
	console.log("updated");
})


   


const handleFilePondInit = ()=> {
	console.log('FilePond has initialized');

	// example of instance method call on pond reference
	pond.value.getFiles();
}





const serverOptions = {
	// url: coverUploadUri.value,
	process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {   
		const formData = new FormData();
		formData.append(fieldName, file, file.name);
            
		const request = new XMLHttpRequest();
		request.open('POST', process.env.VUE_APP_SERVICE_URL+'/files');
	
		request.setRequestHeader('Authorization', 'Bearer '+store.state.authentication.token);
		request.upload.onprogress = (e) => {
			progress(e.lengthComputable, e.loaded, e.total);
			
      
		};

		request.onload = function () {
			
			if (request.status == 200) {
				
				// the load method accepts either a string (id) or an object
				
				const response = JSON.parse(request.responseText);
				imageArray.value.push(response?.data);
				load();
			} else {
				// Can call the error method if something is wrong, should exit after
				console.log('error',request.status);
				if(request.status == 404){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:request.statusText,
						color:'danger',
						timeout:2000});
				}else{
					console.log('request',JSON.parse(request.responseText))

					let jsonData = JSON.parse(request.responseText);
					if(jsonData?.message =='jwt expired'){
						store.commit('snackbar/showSnackbar',{
							show:true,
							message:'Token expired',
							color:'danger',
							timeout:2000});
					    store.commit('authentication/logout',2000);
					}else{
						store.commit('snackbar/showSnackbar',{
							show:true,
							message:jsonData?.message,
							color:'danger',
							timeout:2000});
					}
						
				}

			} 
		};

		request.onerror = () => {
			// Check if the browser indicates a network error
			if (!navigator.onLine) {
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:'Network error ',
					color:'danger',
					timeout:2000});
			} 
		};
	

		request.send(formData);

		return {
			abort: () => {
				// This function is entered if the user has tapped the cancel button
				request.abort();

				// Let FilePond know the request has been cancelled
				abort();
			},
		};

    

	},
	remove: (source, load, error) => {
		// Should somehow send `source` to server so server can remove the file with this source
    
		// error('oh my goodness');
		const filteredItem = myFiles.value.find(item => item.source === source);
		console.log('filteredItem',filteredItem.options.fullUrl)
		const indexToRemove = imageArray.value.findIndex(item => item === filteredItem.options.fullUrl);
		
		console.log('indexToRemove',indexToRemove);
		if (indexToRemove !== -1) {
			imageArray.value.splice(indexToRemove, 1);
		}

		// Should call the load method when done, no parameters required
		load();
	}
	
	
}

function cancelUpdate(){
	router.push({name:'broadcast'});
}

function base64toFile(base64String, fileName, mimeType) {
	// Decode the base64 string to binary data
	const binaryString = atob(base64String);

	// Convert the binary data to an array buffer
	const bytes = new Uint8Array(binaryString.length);
	for (let i = 0; i < binaryString.length; i++) {
		bytes[i] = binaryString.charCodeAt(i);
	}
	const byteArray = new Uint8Array(bytes);

	// Create a Blob from the array buffer
	const blob = new Blob([byteArray], { type: mimeType });

	// Create a File object from the Blob with the specified file name
	return new File([blob], fileName, { type: mimeType });
}


function getFileExtension(filename) {
	// Find the last occurrence of the period (.)
	const lastDotIndex = filename.lastIndexOf('.');
	// If a period is found and it's not the last character, extract the substring after it
	if (lastDotIndex !== -1 && lastDotIndex < filename.length - 1) {
		if(filename.substring(lastDotIndex + 1) =='jpg'){
			return 'image/jpeg';
		}

		if(filename.substring(lastDotIndex + 1) =='png'){
			return 'image/png';
		}

		if(filename.substring(lastDotIndex + 1) =='pdf'){
			return 'application/pdf';
		}

		if(filename.substring(lastDotIndex + 1) =='docx'){
			return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
		}
	}
	// If no period is found or it's the last character, return an empty string
	return '';
}
  
</script>
  
  
  
  
  <style scoped>
  .form-container{display:flex;}
  .form-card{box-shadow: 0px 2px 12px 0px #80808040;
   border-radius: 12px;width: 97%;margin: 12px;
  background-color:white;padding: 17px;
 
 }
 .form-card:nth-child(1){
  flex-basis:55%;
 }
 .form-card:nth-child(2){
  flex-basis:45%
 }
 .form-sets{min-height:100%;}
  .form-card h3{margin-bottom: 15px;}
  .file-limitation{color: gray;font-size: 14px;font-weight: 300;}
  .form-row{display: flex;}
  label{text-align: left;color:#0C4069;margin-bottom: 4px;font-weight: 600;}
  label span{color:red;}
  input + span ,select + span ,textarea + span ,.input-group + span,.vue-tel-input + span,.p-editor-container + span{color:red;text-align: left;font-size: 12px;}
  .form-col-half,.form-col-full{display: flex;flex-direction: column;flex:1;margin-bottom: 14px;}
  .form-col-half{margin-right:30px;}
  .form-col-half:last-child{margin-right:0px;}
  .form-profile{flex-basis: 20%;margin-right: 15px;}
  .profile-container{position: relative; width:170px;height: 170px;border-radius: 50%;margin: auto;
      display: block;}
  
      
      
  .title{flex-basis: 14%!important;flex:unset;}
  .form-col-half input,.form-col-full input,.form-col-half select,.form-col-full select,.form-col-full textarea{border:1px solid #B2B2B2;border-radius: 5px;
      height: 38px;padding: 4px 13px;width:100%;text-transform: capitalize;}
   .form-col-half input:focus,.form-col-full input:focus,.form-col-half select:focus,.form-col-full textarea{outline: unset;}   
   select{border: 1px solid #B2B2B2;
      padding: 6px;
      border-radius: 5px;
      cursor: pointer;
      outline: unset;
      color:black;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      -o-appearance: none;
      appearance: none;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAHCAYAAAD9NeaIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NDkxMSwgMjAxMy8xMC8yOS0xMTo0NzoxNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKE1hY2ludG9zaCkiIHhtcDpDcmVhdGVEYXRlPSIyMDE1LTA0LTE3VDE3OjEyOjQyKzAyOjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTU4MjBDRURERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTU4MjBDRUVERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2RUVFRDJCNkREQzMxMUU0QTM3QUI4MEQzQjkxMTEyOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNTgyMENFQ0RGNUIxMUU0QTM3QUI4MEQzQjkxMTEyOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuShL/sAAABeSURBVHjaYszOzjZnYGDYCcT8DMSBv0AcP2XKlKVEqmdgAuKTQOwOxB+JtQCIibYAZgkDkRaRZQGyJYQsItsCdEtwWUSRBdgsQbeIYgtAgAWHOMwiJSBezkAhAAgwAJSTG/DI0S9VAAAAAElFTkSuQmCC);
      background-position: 100% 60%;
      background-repeat: no-repeat;
      background-size: auto 15%;
  }
  .form-actions{display: flex;
	/* justify-content: flex-end; */
}
  .form-actions button{background-color: #007CBA;
      color: white;
      border-radius: 7px;
      padding: 6px 28px;
      margin-right: 18px;
	}
  .error-msg{text-align: left;
      color: red;
      font-size: 12px;}
  :deep() .iti__selected-flag{background-color:unset;}
  
  /* date picker */
  .input-group {display: flex;border:1px solid #B2B2B2;border-radius: 5px;}
  :deep() .form-col-half  .date-form-control{
    width: 100%;height: 37px;padding: 12px;}
  
   :deep() input.date-form-control {border: unset!important;outline:unset!important;} 
    
    .input-group-append {
    display: flex;
    align-items: center; /* Aligns buttons vertically */
    /* Add additional styles as needed */
  }
  .input-group-append button{margin-right: 7px;}
  .input-group-append .fa-calendar,.input-group-append .fa-clock{color:#B2B2B2;}
  .radio-group{display: flex;}

  :deep() .upload-icon{position:relative;margin-top: 42px;}
    :deep() .upload-icon::after{
     width: 97px;
    content: '';
    height: 97px;
    background-image: url(../../assets/images/file-upload.png);
    background-size: contain;
    display: flex;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -75%);}  
	
	:deep() .filepond--drop-label{min-height: 12.75em;border:2px dashed #C5C5C5;border-radius: 9px;}
	button:disabled {
	opacity: 0.5; 
	cursor: not-allowed; 
	}
  @media only screen and (max-width: 768px) {
    .form-container{flex-direction: column;}
    .form-card:nth-child(1),.form-card:nth-child(2){flex-basis: 100%;width:unset;}
    .form-row{flex-direction: column;}
    .form-col-half, .form-col-full{margin-right: unset;}
  }
  </style>
  
  