import axios from 'axios';
import { showErrorMessage } from '@/helper/index.js';

 
// Create an axios instance
const apiClient = axios.create({
	baseURL: process.env.VUE_APP_SERVICE_URL, // Base URL of your API
	headers: {
		'Content-Type': 'application/json'
	},
	timeout: 10000, // Request timeout
});

// Add a request interceptor
apiClient.interceptors.request.use(
	config => {
		// Do something before request is sent
		const token = localStorage.getItem('token'); // or any other storage you're using

		if (token) {
			config.headers.Authorization = token;
		}
		return config;
	},
	error => {
		// Do something with request error
		
		return Promise.reject(error);
	}
);

// Add a response interceptor
apiClient.interceptors.response.use(
	response => {
		// Do something with response data
		return response;
	},
	error => {

		// if(error.response){
		// 	console.log('INTERCEPTOR DATA',error.response.data)
		// 	console.log('INTERCEPTOR STATUS',error.response.status)
		// 	console.log('INTERCEPTOR HEADER',error.response.header)
		// 	showErrorMessage(error.response.data)
			
		// }else{
		// 	console.log('INTERCEPTOR ERROR',error)
		// 	console.log('INTERCEPTOR ERROR MEssage',error.message)
		// 	showErrorMessage(error.message,2000)
		// }
		
		return Promise.reject(error);
	}
);

export default apiClient;
