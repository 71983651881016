<template>
  <div class="form-card">
    <v-skeleton-loader
      v-if="fetching"
      type="ossein"
      height="250"
    />
    <Form
      v-else
      ref="form"
      :validation-schema="schema"
      @submit="onSubmit"
    >
      <div class="form-row">
        <div class="form-col-half">
          <label for="firstName">First Name<span>*</span> </label>
          <Field
            v-model="firstName"
            name="firstName"
            type="text"
          />
          <ErrorMessage name="firstName" /> 
          <span />
        </div>
        <!-- <div class="form-col-half">
          <label for="middleName">Middle Name</label>
          <Field
            v-model="middleName"
            name="middleName"
            type="text"
          />
          <ErrorMessage name="middleName" /> 
        </div> -->

        <div class="form-col-half">
          <label for="lastName">Last Name<span>*</span> </label>
          <Field
            v-model="lastName"
            name="lastName"
            type="text"
          />
          <ErrorMessage name="lastName" /> 
        </div>

        <div class="form-col-half gender">
          <label for="gender">Gender</label>

          <Field
            v-model="gender"
            name="gender"
            as="select"
          >
            <option
              value=""
              selected
              style="display: none;visibility: hidden;"
            >
              Gender
            </option>
            <option value="male">
              Male
            </option>
            <option value="female">
              Female
            </option>
            <option value="others">
              Others
            </option>
          </Field>
      
          <ErrorMessage name="gender" />
        </div>
      </div>

      <div class="form-row">
        <div class="form-col-half">
          <label for="phoneNumber">Phone Number<span>*</span>  </label>
     

          <!-- <Field
            id="phoneNumber"
            v-slot="{field}"
            name="phoneNumber"
            type="tel"
          >
            <vue-tel-input
              v-bind="field"
              v-model="field.value"
            /> 
          </Field>
          <ErrorMessage name="phoneNumber" /> -->


          <Field
            id="phoneNumber"
            v-slot="{field}"
            v-model="phoneNumber"
            name="phoneNumber"
            type="tel"
            @input="phoneNumberChanged"
            @blur="phoneNumberChanged"
          >
            <vue-tel-input
              v-bind="field"
              v-model.trim="field.value"
              :input-options="options"
              @country-changed="countryChanged"
            /> 
          </Field>
          <span>     
            <ErrorMessage
              v-if="isPhoneNumberTouched"
              name="phoneNumber"
            />
          </span>
        </div>
    

        <div class="form-col-half">
          <label for="email">Email</label> 
      
          <Field
            v-model="email"
            name="email"
            type="text"
          />
          <ErrorMessage name="email" />
        </div>

        <div class="form-col-half">
          <label for="chapter">Chapter<span>*</span></label> 
          <Field
            v-model="chapter"
            name="chapter"
            type="text"
          />
          <ErrorMessage name="chapter" />
        </div>
      </div>

      <!-- <div class="form-row">
        
        <div class="form-col-half" />
        <div class="form-col-half" />
      </div> -->

      <div class="form-row">
        <div
          class="form-col-full"
          style="margin-right:unset"
        >
          <div class="form-actions">
            <button
              type="button"
              @click="resetForm"
            >
              Reset
            </button>
            <button
              type="submit"
              :disabled="saveMember?true:false"
            >
              <fa
                v-show="saveMember===true"
                icon="spinner"
                spin
              /> 
              Save
            </button>
          </div>
        </div>
      </div>
    </Form>
  </div>
   
  <!-- snackbar -->
  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
    color="success"
  >
    {{ text }}

    <template #actions>
      <v-btn
        color="white"
        variant="text"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
  <!-- snackbar -->
</template>





<script setup>
import {ref, onMounted} from 'vue'
import { isValidPhoneNumber} from "libphonenumber-js/mobile"; 
import { useStore } from 'vuex';
import { useRoute,useRouter } from 'vue-router';
import { Form, Field, ErrorMessage} from 'vee-validate';
import * as yup from 'yup';
import axios from 'axios';
import {getMember, createMember} from '@/service/helper-service.js';
const route = useRoute();
const router = useRouter();
const store = useStore(); 
const memberId = ref(route.params.id);




const firstName = ref('');
const middleName = ref('');
const lastName = ref('');
const gender = ref('');
const email = ref('');
const phoneNumber = ref('');  
const chapter = ref('');
const form  = ref();
const dialCode = ref('');
// const chapter = ref('');
const	timeout= ref(2000);
const snackbar= ref(false);
const text= ref('');
const saveMember = ref(false);
const fetching = ref(false);

const isPhoneNumberTouched = ref(false);
const isDobTouched = ref(false);

const phoneNumberChanged = () => {
	isPhoneNumberTouched.value = true;
}

const dobChanged = () => {
	isDobTouched.value = true;
}


const options = ref({ placeholder: "Phone Number" })

const schema = yup.object({
	firstName: yup.string().required('Please enter firstname'),
  lastName: yup.string().required('Please enter lastname'),
	phoneNumber:yup.string()
		.test('phone-validation', 'Please enter Number', function(value) {
			if (!value) {
				return false; // Validation failed
			}
			return true;
		})
		.test('invalid-validation', 'Invalid Phone Number', function(value) {
			if (value && !isValidPhoneNumber(value)) {
				return false; // Validation failed
			}
			return true;
		}).nullable(),
	email: yup.string('Please enter email').email('Invalid Email'),
	chapter: yup.string().required('Please enter chapter'),
});

const countryChanged = (country) =>{
	console.log(country);
	dialCode.value = country.dialCode;
}

const onSubmit = async(data) => {
	console.log('data',data);
	saveMember.value = true;

	const createData = [{
		"firstName": data.firstName,
		// "middleName":data.middleName,
		"lastName":  data.lastName,
		"email": data.email,
		"phone": {
			"countryCode": '+'+dialCode.value,
			"number":data.phoneNumber.replace(data.phoneNumber.split(' ')[0],'').replace(/\D/g, '')
		},
		"gender":data.gender,
		"chapter":data.chapter
        
	}]

	const updateData = {
		"userId":memberId.value,
		"data":{"firstName": data.firstName,
			"middleName":data.middleName,
			"lastName":  data.lastName,
			"email": data.email,
			"phone": {
				"countryCode": dialCode.value,
				"number":data.phoneNumber.replace(data.phoneNumber.split(' ')[0],'').replace(/\D/g, '')
			},
			"gender":data.gender,
			"chapter":data.chapter,
			"userStatus":"active"}
	}

	const payload = memberId.value ? updateData : createData;
	const method = memberId.value ? 'put' : 'post';
	const url = memberId.value ? `${process.env.VUE_APP_SERVICE_URL}/users` : `${process.env.VUE_APP_SERVICE_URL}/admin/users`;
	try {
		const response =  await createMember(method,url,payload);
 
		if(response.status === 200){
			saveMember.value = false;
			if(memberId.value){
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:'Updated Successfully',
					color:'success',
					timeout:2000});
				router.push({name:'members'});
			}else{
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:'Added Successfully',
					color:'success',
					timeout:2000});
				resetForm();
			}
				
		}

	}catch (error) {
		//console.log(error)
		saveMember.value = false;
		if (error.response) {
			if(error.response.data.message){
				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}

				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});
							

			}else{
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}
	}
	
};

onMounted(()=>{
	if(memberId.value){
		loadMemberDetails(memberId.value) 
	}
	firstName.value = '';
})

const loadMemberDetails = async(id)=>{
  fetching.value = true;
	try {
		const response = await getMember(`/users?id=${id}`);
		console.log('Fetch data',response);
		let memberDetail = response?.data?.data;
		console.log("memberDetail",memberDetail)
		firstName.value = memberDetail?.firstName;
		middleName.value = memberDetail?.middleName;
		lastName.value = memberDetail?.lastName;
		gender.value = memberDetail?.gender;
		email.value = memberDetail?.email;
		chapter.value = memberDetail?.chapter;
		phoneNumber.value = memberDetail?.phone?.number;
		dialCode.value = memberDetail?.phone?.countryCode;
	} catch (error) {
		if (error.response) {
			if(error.response.data.message){

				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}

			}else{
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}
	}finally{
		fetching.value = false;
	}
}

const resetForm = ()=>{
	form.value.resetForm()  
	setTimeout(()=>{
		form.value.setFieldError('phoneNumber', '') 
	},100)
}


</script>




<style scoped>
.form-card{box-shadow: 0px 10px 15px 0px rgba(124, 124, 124, 0.20);border-radius: 12px;width: 80%;margin: 25px auto 0;
background-color:white;padding: 17px;}
.form-row{display: flex;}
label{text-align: left;color:#0C4069;margin-bottom: 4px;font-weight: 600;}
label span{color:red;}
input + span ,.vue-tel-input + span{color:red;text-align: left;font-size: 12px;}
.form-col-half,.form-col-full{display: flex;flex-direction: column;flex:1;margin-right:30px;margin-bottom: 20px;}
.form-col-half:last-child{margin-right:0px;}
.gender{flex-basis: 20%!important;flex:unset;}
/* .form-col-half{flex-basis: 50%;} */
/* .form-col-full{flex-basis: 100%;} */
.form-col-half input,.form-col-full input,.form-col-half select,.form-col-full select{border:1px solid #B2B2B2;border-radius: 5px;
    height: 38px;padding: 4px 13px;width:100%;}
 .form-col-half input:focus,.form-col-full input:focus,.form-col-half select:focus{outline: unset;}   
.form-actions{display: flex;justify-content: flex-end;}
.form-actions button{background-color: #007CBA;
    color: white;
    border-radius: 7px;
    padding: 6px 28px;
    margin-left: 18px;}
.error-msg{text-align: left;
    color: red;
    font-size: 12px;}
:deep() .iti__selected-flag{background-color:unset;}
</style>

