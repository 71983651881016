<template>
  <div style="position: fixed;margin:auto;display: block;width: 100%;">
    <AdminPanel>
      <v-container>
        <div class="card-container">
          <div
            class="card"
            :class="{active:selectedInfoTab ==='TotalMembers'}"
            @click="selectInfoCard('TotalMembers')"
          >
            <div class="card-group">
              <fa icon="circle-check" />
              <div class="count">
                {{ totalMember }}
              </div>
            </div>
            <div class="card-name">
              Total Members
            </div>
          </div>

          <div
            class="card"
            :class="{active:selectedInfoTab ==='PendingRegistration'}"
            @click="selectInfoCard('PendingRegistration')"
          >
            <div class="card-group">
              <fa icon="circle-check" />
              <div class="count">
                {{ pendingRegistrationMember }}
              </div>
            </div>
            <div class="card-name">
              Pending Registration
            </div>
          </div>
 
          <div
            class="card"
            :class="{active:selectedInfoTab ==='RegisteredMembers'}"
            @click="selectInfoCard('RegisteredMembers')"
          >
            <div class="card-group">
              <fa icon="registered" />
              <div class="count">
                {{ registeredMembers }}
              </div>
            </div>
            <div class="card-name">
              Registered Members
            </div>
          </div>
        </div>

        <div>
          <MembersList
            v-if="selectedInfoTab ==='TotalMembers'"
            :type="'all'"
          />
          <MembersList
            v-if="selectedInfoTab ==='PendingRegistration'"
            :type="'pending'"
          /> 
          <MembersList
            v-if="selectedInfoTab ==='RegisteredMembers'"
            :type="'completed'"
          /> 
        </div>
      </v-container>
    </AdminPanel>
  </div>
</template>


<script>
// @ is an alias to /src
import AdminPanel from '@/components/ui/AdminPanel.vue'
import axios from 'axios';
import MembersList from '@/components/members/MembersList.vue';

export default {
	components: {
		AdminPanel,
		MembersList
	},
	data(){
		return {
			selectedInfoTab:'TotalMembers',
			authToken:'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwaG9uZSI6IjkwMjUyNTI3OTMiLCJ1c2VySWQiOjExNywicm9sZSI6ImFkbWluIiwiaWF0IjoxNzIyNzQ0ODQzLCJleHAiOjE3MzA1MjA4NDN9.ILDOy4gXVaMHR5fnuzN0UWHt36jvsBSUTsgye3Uetw0',
			totalMember:0,
			pendingRegistrationMember:0,
			blockedMember:0,
			inactiveMember:0,
			registeredMembers:0
		}},
	mounted(){
		axios
			.get(`https://9d5ik6oyw2.execute-api.ap-south-1.amazonaws.com/ccs/admin/users/count`,{
				headers: {
					'Authorization': `Bearer ${this.authToken}`,
				}})
			.then((response) => {
				let membersCounts = response?.data?.data; 
        
				this.totalMember = membersCounts?.allUsers;
				this.pendingRegistrationMember = membersCounts?.pending; 
				this.registeredMembers =  membersCounts?.completed;

			})
	},
	methods:{
		selectInfoCard(tabName){
			this.selectedInfoTab = tabName;
           
		}
	}
}
</script>

<style scoped>
  .card-container{display: flex;justify-content: space-evenly;margin-top: -5px;}
   .card{border-radius: 6px;
    border: 1px solid #d3d2d2;
    background-color: #f7f6f6;
    padding: 16px 15px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 13px;
    cursor: pointer;
    
    }
    .card:nth-child(1){margin-left: unset;}
  .card:hover,.card.active{background-color: #007CBA;color:#fff;transition: ease-in-out .8s;}
  .card-group{display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;}
 
.card-name{font-weight: 600;margin-top: 12px;font-size: 14px;text-align: left;}
.card .svg-inline--fa{padding: 8px;border-radius: 50%;font-size: 21px;}
.card:nth-child(1) .svg-inline--fa{color: #8BC34A;background-color: #ddf2c4;}
.card:nth-child(2) .svg-inline--fa{color:#F2881D;background-color: #EFE3D7;}
.card:nth-child(3) .svg-inline--fa{color: #3632D4;background-color: #cbcade;}
.card:nth-child(4) .svg-inline--fa{color: #FF0000;background-color: #fdd7d7;}
.card:nth-child(5) .svg-inline--fa{color: #FF0000;background-color: #fdd7d7;}
</style>


